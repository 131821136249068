import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from './images/logo.png';
import menu from './images/free-icon-menu-4254068.png';

function HeaderBlack() {
  const location = useLocation();
  const isAU = location.pathname.includes('/au'); // Check if the current path contains "/au"

  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark">
      <div
        style={{
          borderRadius: '24px',
          border: '#0f0d1b',
          borderWidth: '2px',
          borderStyle: 'solid',
        }}
        className="container"
      >
        <Link className="navbar-brand" to={isAU ? "/au" : "/"}>
          <img src={logo} alt="logo" /> <span>MapleLuckSpinsCanadian</span>
        </Link>
        <button className="navbar-toggler" type="button" id="burgerMenuButton">
          <img id="burgerIcon" src={menu} alt="menu" onClick={toggleMenu} style={menuOpen ? { filter: "invert(100%)" } : {}} />
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <Link className="nav-item nav-link" to={isAU ? "/au" : "/"}>
              Top Online Casino
            </Link>
            <Link className="nav-item nav-link" to={isAU ? "/au/slot-machines" : "/slot-machines"}>
              Slot Machines
            </Link>
            <Link className="nav-item nav-link" to={isAU ? "/au/roulette" : "/roulette"}>
              Roulette
            </Link>
            <Link className="nav-item nav-link" to={isAU ? "/au/poker" : "/poker"}>
              Poker
            </Link>
            <Link className="nav-item nav-link" to={isAU ? "/au/live-casino" : "/live-casino"}>
              Live Casino
            </Link>
            <Link className="nav-item nav-link" to={isAU ? "/au/blackjack" : "/blackjack"}>
              Blackjack
            </Link>
          </div>
        </div>
        <div id="mobileMenu" className={`mobile-menu ${menuOpen && 'show'}`}>
          <div className="navbar-nav white-links flex-column">
            <Link className="nav-item nav-link" to={isAU ? "/au" : "/"}>
              Top Online Casino
            </Link>
            <Link className="nav-item nav-link" to={isAU ? "/au/slot-machines" : "/slot-machines"}>
              Slot Machines
            </Link>
            <Link className="nav-item nav-link" to={isAU ? "/au/roulette" : "/roulette"}>
              Roulette
            </Link>
            <Link className="nav-item nav-link" to={isAU ? "/au/poker" : "/poker"}>
              Poker
            </Link>
            <Link className="nav-item nav-link" to={isAU ? "/au/live-casino" : "/live-casino"}>
              Live Casino
            </Link>
            <Link className="nav-item nav-link" to={isAU ? "/au/blackjack" : "/blackjack"}>
              Blackjack
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default HeaderBlack;
